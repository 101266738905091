<template>
  <div id="App">
    <router-view></router-view>
  </div>
</template>

<script>


export default {

  name: 'App',
  components: {
    // HelloWorld
  },
  data() {
    return {
      routerAlive:true
    };
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  height: 100%;
}
</style>
