import { createRouter, createWebHashHistory } from "vue-router"

const routes = [
    {
        path: '/map',
        name:'map',
        component: () => import('@/components/HelloWorld')
    },
    {
        path: '/',
        name:'home',
        component: () => import('@/components/HomePage')
    },



]
export const router = createRouter({
    history: createWebHashHistory(),
    routes: routes
})

export default router
