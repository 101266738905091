import { createApp } from 'vue'
import App from './App.vue'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import router from './router'
// import VueGeolocation from "vue3-geolocation/src";

createApp(App)
    .use(router)
    .use(ElementPlus)
    // .use(VueGeolocation)
    .mount('#app')



